/* 越南语 */
export default {
  emailRegister: 'Đăng ký Email',
  registerNotice: 'Tài khoản chưa đăng ký sẽ được tự động đăng ký và đăng nhập',
  enterEmail: 'Vui lòng nhập địa chỉ email của bạn',
  enterCode: 'Vui lòng nhập mã của bạn',
  getCode: 'Nhận mã',
  signIn: 'Đăng nhập ngay',
  signPropt: 'Bằng cách đăng nhập, bạn đồng ý với',
  policy: 'Chính sách Bảo mật',
  agreement: 'Thỏa thuận Người dùng',
  emailRuleError: 'Định dạng email không chính xác',

  starcard: `Thẻ Ngôi Sao Thành Viên`,
  more: `Thêm`,
  describe: `Thẻ Ngôi Sao là bằng chứng về quyền lợi trong hệ thống quảng bá của Jubaopen. Chỉ những người sở hữu Thẻ Ngôi Sao mới có thể hưởng các phúc lợi và quyền lợi trong hệ thống quảng bá của Jubaopen. Thẻ Ngôi Sao được chia thành Thẻ Ngôi Sao Trải Nghiệm và Thẻ Ngôi Sao Chuyên Dụng. Người dùng Thẻ Ngôi Sao có thể nhận được phần thưởng tiền mặt.`,
  starReward: `Phần thưởng quảng bá Thẻ Ngôi Sao`,
  unlock: `Mở khóa`,
  text1: `· Phần thưởng mời: Mời trực tiếp hoặc gián tiếp người dùng mua Thẻ Ngôi Sao thành viên có thể nhận được phần thưởng 10% và 5%.`,
  text2: `· Phần thưởng quảng bá đầu tư bản quyền: Mời trực tiếp hoặc gián tiếp người dùng đầu tư quảng bá bản quyền, nhận phần thưởng hàng ngày 10% và 5%.`,
  text3: `· Phần thưởng xem phim: Mời trực tiếp người dùng mở khóa một tập phim có thể nhận được phần thưởng 40%, hoặc đăng ký thành viên phim ngắn có thể nhận được phần thưởng 20%.`,
  starClu: `Phần thưởng nhóm ngôi sao`,
  text4: `Vui lòng liên hệ chăm sóc khách hàng hoặc người mời để biết quy tắc cụ thể`,
  copyright: `Gây quỹ bản quyền`,
  td1: `Tỷ suất lợi nhuận hàng năm`,
  td2: `Số tiền đầu tư ban đầu`,
  td3: `Đã hoàn thành/Tổng số tiền`,
  warning: `*Lợi nhuận sẽ dao động hàng ngày tùy thuộc vào hiệu quả quảng bá thực tế.`,
  schedule: `Tiến độ gây quỹ`,
  buynow: `Mua ngay`,
  download: `Tải xuống`,
  go: `Đi đến`,
  android: `Android`,
  apple: `App Store`,
  Characteristic: `Đặc điểm`,
  Participation: `Số tiền tham gia`,
  method: `Phương thức thanh toán`,
  amount: `Số tiền thanh toán`,
  jump: `Chuyển đến thanh toán`,
  name1: `Phim ngắn`,
  name2: `Nhiều loại`,
  name3: `Thành viên Thẻ Ngôi Sao`,
  name4: `Thu nhập từ ống dẫn`,
  describe1: `Bảo đảm chất lượng`,
  describe2: `Tưởng tượng điều bạn muốn`,
  describe3: `Trợ cấp hàng tỷ`,
  describe4: `Ngành phụ đứng đầu`,
  warning1: `Nội dung nhập không thể để trống`,
  success1: `Liên kết thành công`,
  ends: `Đếm ngược kết thúc`,
  warning2: `Bạn chưa đăng nhập, vui lòng đăng nhập trước`,
};
